<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Lost Users</v-toolbar-title>
      <v-combobox style="display: block; position: absolute; left: 125px;" v-model="format" :items="formatItems" item-value="id" item-text="value" @change="initialize"
        hide-details label="How Days Ago"></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="result.data"
      class="elevation-1"
    >
      <template v-slot:item.u="{item}">
        <UserDetailCard
          :userId="item.u.i"
          :pictureUrl="item.u.p"
          :userName="item.u.n"
          :level="item.u.l"
        />
      </template>
      <template v-slot:item.r="{ item }">
          <div>{{ unixTimestampToFormattedDate(item.r) }}</div>
      </template>

      <template v-slot:item.l="{ item }">
          <div>{{ unixTimestampToFormattedDate(item.l) }}</div>
      </template>

      <template v-slot:item.rtp="{ item }">
        <div v-bind:style="{color: item.rtp!==NaN && item.rtp > 100 ? 'lime' : '#FF6D6D'}">%{{ item.rtp!=="NaN"?item.rtp:0}}</div>
      </template>

    </v-data-table>
  </div>
</template>
<script>

import rewardType from "@/lib/rewardType";
export default {
  components: {
    UserDetailCard: () => import("@/components/Users/UserDetailCard.vue"),
  },
  data() {
    return {
      rewardType: rewardType,
      dialogDelete: false,
      dialogEdit: false,
      currentItem: {},
      detail: {},
      all: [],
      format: { id: 5, value: "Five Days" },
      formatItems: [
      { id: 1, value: "One Day" },
      { id: 2, value: "Two Days" },
      { id: 3, value: "Three Days" },
      { id: 4, value: "Four Days" },
      { id: 5, value: "Five Days" },
      { id: 6, value: "Six Days" },
      { id: 7, value: "Seven Days" },
      { id: 8, value: "Eigth Days" },
      { id: 9, value: "Nine Days" },
      { id: 10, value: "Ten Days" },
      ],
      headers: [
        { text: "User", value: "u" },
        { text: "Email", value: "e" },
        { text: "Country", value: "c" },
        { text: "Level", value: "lv" },
        { text: "Spin Count", value: "s" },
        { text: "User RTP", value: "rtp" },
        { text: "Register Date", value: "r" },
        { text: "Last Online", value: "l" },
      ],
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      itemLevelRewards: {},
      itemBigReward: {},
      itemsPerPage: 500,
      singleSelect: false,
      selected: [],
      result: [],
      pagination: {
        sortBy: "id",
      },
    };
  },
  methods: {
    
    async initialize() {
      // eslint-disable-next-line no-console
      console.log("format :"+this.format.id);
      this.result = await this.$store.dispatch("lostUsers/get",{format:this.format});
      this.all = this.$store.state.lostUsers.all;
    },
    convertTRDate(date){
      var inputDate = new Date(date);

      var formattedDate = new Intl.DateTimeFormat('tr-TR').format(inputDate);
      return formattedDate;
    },

    unixTimestampToFormattedDate(unixTimestamp){
    if(unixTimestamp===null)
        return null;
    var date = new Date(unixTimestamp * 1000); // Unix zaman damgasını milisaniyeye çevir
    var options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    var formattedDate = new Intl.DateTimeFormat('tr-TR', options).format(date);
    return formattedDate;
    }
  },

  async mounted() {
    await this.initialize();
  },

};

</script>
